<template >
  <div class="containter">
    <div class="commentArea">
      <van-field
        v-model="commentText"
        rows="7"
        maxlength="200"
        show-word-limit
        autosize
        type="textarea"
        placeholder="请输入回复内容"
      />
    </div>
    <!-- <div class="countLine">
      <div class="leftField">
        <van-field
          v-model="digit"
          type="digit"
          label="任务得分"
          label-class="lableFont"
          placeholder="最高得分10分"
        />
      </div>
      <div class="rightField">分</div>
    </div> -->
    <div class="submitButton" @click="submit">提交</div>
  </div>
</template>

<script>
import { replyFeedBack } from '@/api/feedback';
import { Toast } from 'vant';

export default {
  name: 'SituationFeedbackSubmit',
  data() {
    return {
      id: '',
      commentText: '',
    };
  },
  watch: {},
  created() {
    const { id } = this.$route.query;
    this.id = id
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    submit() {
      //提交
      replyFeedBack({
        id: this.id,
        solution: this.commentText,
      }).then(res => {
        console.log(res)
        if(res.success){
          Toast('提交成功');
          setTimeout(()=>{
            this.$router.go(-1);
          }, 500)
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.commentArea {
  width: 100%;
  height: 200 * $px;
  background-color: #ffffff;
  position: relative;
}
.imgLine {
  width: 100%;
  height: 90 * $px;
  position: absolute;
  bottom: 10 * $px;
  left: 0px;
}
.marginLeft12 {
  margin-left: 12 * $px;
}
.addImg {
  width: 80 * $px;
  height: 80 * $px;
}
.submitButton {
  margin: 30 * $px 15 * $px;
  width: 345 * $px;
  height: 44 * $px;
  border-radius: 22 * $px;
  text-align: center;
  line-height: 44 * $px;
  background-color: #ff192f;
  color: #ffffff;
  font-size: 16 * $px;
}
.countLine {
  width: 100%;
  height: 50 * $px;
  background-color: #ffffff;
  margin-top: 17 * $px;
}
.lableFont {
  font-size: 14 * $px;
  color: #555555 !important;
}
.leftField {
  width: 340 * $px;
  height: 100%;
  float: left;
}
.rightField {
  width: 35 * $px;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  color: #222222;
  font-size: 14 * $px;
}
</style>



